import { T } from "@/locales";

export const columns = [
  {
    title: T("序号"),
    dataIndex: "index",
    customRender: (_, __, index) => index + 1,
  },
  {
    title: T("产品名称"),
    dataIndex: "material_name",
    ellipsis: true,
  },
  {
    title: T("产品编号"),
    dataIndex: "material_number",
  },
  {
    title: T("产品条码"),
    dataIndex: "material_barcode",
  },
  {
    title: T("状态"),
    dataIndex: "status_display",
  },
  {
    title: T("批次编号"),
    dataIndex: "batch_number",
  },
  {
    title: T("容器编号"),
    dataIndex: "pallet_number",
  },
  {
    title: T("库位编号"),
    dataIndex: "location_number",
  },
  {
    title: T("库存数量"),
    dataIndex: "remain_quantity",
  },
  {
    title: T("操作"),
    dataIndex: "action",
    scopedSlots: { customRender: "action" },
  },
];
